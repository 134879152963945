import {
  ClockCircleOutlined,
  CloseCircleFilled,
  EditOutlined,
  MailOutlined,
  PaperClipOutlined,
  PhoneOutlined,
  ProfileOutlined,
  ReloadOutlined,
  SaveOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button as Btn,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  MenuProps,
  Row,
  Tag,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Colors from "src/themes/colors";
import { MSG_CHAR_LIMIT, MSG_UNIT_LIMIT, PAGINATION, PhoneNumberRegex, maxSize } from "src/constants/common";
import { isValidFile, returnElipsesText } from "src/constants/functions";
import { IAddVariable, IMessages } from "src/constants/types";
import { firstName } from "src/constants/add-variable";
import { STATUS } from "src/constants/status";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { getConversationByIdData, getIsConversationByIdNewFetch } from "src/store/selectors/features/conversationByid";
import { getConversationUser } from "src/store/selectors/features/conversationUser";
import { getCurrentDidId, getCurrentDidStatus } from "src/store/selectors/features/currentDid";
import { getMessageLoadingState } from "src/store/selectors/features/message";
import { getIsSelectingContactUser } from "src/store/selectors/features/contactUser";
import { getTemplateData } from "src/store/selectors/features/template";
import { uploadFilesLoading } from "src/store/selectors/features/uploadFiles";
import { getLoggedInUserData } from "src/store/selectors/features/loggedInUser";
import { clearConversationById } from "src/store/slices/features/conversationsById";
import { setConversationUser } from "src/store/slices/features/currentUserConversation";
import { setAllConversationsById } from "src/store/slices/features/allConversations";
import RequestAppAction from "src/store/slices/appActions";
import { AddTemplateModal } from "../settings/templates/addTemplateModal";
import Button from "src/components/button";
import ListingSidebar from "src/components/listing-sidebar";
import { TextifyModal } from "src/components/modal";
import BulkMessageModal from "src/components/bulk-message-modal/BulkMesssageModal";
import { AddScheduleMessageModal } from "src/components/listing-sidebar/addScheduleMessageModal";
import { replaceContentVariables } from "src/components/modal-button-container/add-variable/utils";
import ConversationMessages from "src/components/conversationMessages";
import SendButton from "src/components/button/sendButton";
import "./converstions.scss";

const { Header, Sider } = Layout;
const { Text, Title } = Typography;

interface modalProps {
  openModal: (text: string) => void;
  closeModal: () => void;
}

let tempUser: any = null;
let tempDid: null | number;

const Converstion = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const [form] = useForm();

  const editContactModalRef = useRef<modalProps>(null);
  const uploadFileRef: any = useRef<HTMLInputElement | null>(null);
  const scheduleMessageModalRef = useRef<modalProps>(null);
  const templateModalRef = useRef<modalProps>(null);
  const scrollRef: any = useRef(null);

  const isLoading = useSelector(getMessageLoadingState);
  const isSelectingContactUser = useSelector(getIsSelectingContactUser);
  const conversationUser: any = useSelector(getConversationUser);
  const getMessages: any = useSelector(getConversationByIdData);
  const isConversationByIdNewFetch: any = useSelector(getIsConversationByIdNewFetch);
  const did = useSelector(getCurrentDidId);
  const didStatus = useSelector(getCurrentDidStatus);
  const templates: any = useSelector(getTemplateData);
  const sendingMessage = useSelector(getMessageLoadingState);
  const isUploading = useSelector(uploadFilesLoading);
  const currentUserData: any = useSelector(getLoggedInUserData);

  const [inputValue, setInputValue] = useState<string>("");
  const [messages, setMessages] = useState<IMessages>({ meta: null, items: [] });
  const [scrollFetch, setScrollFetch] = useState(false);
  const [pagination, setPagination] = useState(PAGINATION);
  const [state, setState] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [attachments, setAttachments] = useState<any>([]);
  const [units, setUnits] = useState<number>(0);
  const [characters, setCharacters] = useState<number>(0);
  const [variableList, setVariableList] = useState<IAddVariable[]>([]);

  const isPending = currentUserData?.isPending || didStatus !== STATUS.ACTIVE;

  const openModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (!scrollFetch || !conversationUser?.id) {
      return;
    }

    const handler = setTimeout(() => {
      dispatch(
        RequestAppAction.fetchConversationsById({
          id: conversationUser?.id,
          meta: pagination,
          cbSuccess: (data: any) => {
            const arr = [...data?.data?.items];
            setMessages((pre: IMessages) => ({
              ...pre,
              items: [...(pre.items ?? []), ...arr],
            }));

            setPagination((pre: any) => ({
              ...pre,
              page: pagination.page + 1,
            }));

            setScrollFetch(false);
          },
          cbFailure: (e: string) => {
            setScrollFetch(false);
            notification.error({
              message: e ?? t("notification.fetchConversationsByIdFail")
            });
          },
        })
      );
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [scrollFetch]);

  useEffect(() => {
    if (!conversationUser) {
      return;
    }

    // if (conversationUser.id) {
    //   dispatch(
    //     RequestAppAction.getUser({ id: conversationUser.id })
    //   );
    // }

    if (!getMessages) {
      fetchNewData();
    }

    setInputValue("");
  }, [conversationUser]);

  useEffect(() => {
    if (!getMessages) {
      setMessages({ meta: null, items: [] });
    } else if (!scrollFetch) {
      if (scrollRef?.current && !isConversationByIdNewFetch) {
        scrollRef.current.scrollTop = 0;
      }
      setMessages({ 
        ...getMessages, 
        items: [...(getMessages?.items ?? [])]
      });
    }
  }, [getMessages, scrollRef.current]);

  useEffect(() => {
    if (!did) {
      return;
    }

    // if (
    //   templates
    //   && templates.length > 0
    //   && templates[0]?.didId === did
    // ) {
    //   return;
    // }

    // dispatch(
    //   RequestAppAction.fetchTemplate({
    //     id: did,
    //     meta: PAGINATION,
    //     cbFailure: (e: string) => {
    //       notification.error({
    //         message: e ?? t("notification.getTemplateFail")
    //       });
    //     },
    //   })
    // );

    setMessages({ meta: null, items: [] });
  }, [did]);

  const fetchNewData = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }

    if (!conversationUser?.id) {
      notification.error({
        message: t("notification.noConversationSelected"),
      });
    }

    dispatch(
      RequestAppAction.fetchConversationsById({
        id: conversationUser.id,
        meta: PAGINATION,
        cbSuccess: (data: any) => {
          if (data) {
            dispatch(setAllConversationsById(data));
          }
          setPagination((pre: any) => ({
            ...pre,
            page: PAGINATION.page + 1,
          }));
          setIsRefetching(false);
        },
        cbFailure: (e: string) => {
          notification.error({
            message: e ?? t("notification.fetchConversationsByIdFail")
          });
          setIsRefetching(false);
        },
      })
    );
  };

  const onScroll = () => {
    if (!scrollRef?.current) {
      return;
    }

    const {
      scrollTop,
      scrollHeight,
      clientHeight
    } = scrollRef.current;
    const isNearTop = clientHeight - scrollHeight;

    if (
      (-scrollTop + 10 > -isNearTop) &&
      (pagination.page <= getMessages?.meta?.totalPages)
    ) {
      setScrollFetch(true);
    }
  };

  useEffect(() => {
    if (scrollRef?.current) {
      const listInnerElement: any = scrollRef.current;

      if (listInnerElement) {
        listInnerElement.addEventListener("scroll", onScroll);

        return () => {
          listInnerElement.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, [scrollRef.current?.scrollHeight, getMessages]);

  useEffect(() => {
    return () => {
      setPagination((pre: any) => ({
        ...pre,
        page: PAGINATION.page + 1,
      }));
      dispatch(setConversationUser(null));
    };
  }, []);

  useEffect(() => {
    const variables: IAddVariable[] = [];
    variables.push(firstName);
    setVariableList(variables);
  }, []);

  useEffect(() => {
    const charactersLength = inputValue.length;
    const totalUnits = Math.floor(charactersLength / MSG_CHAR_LIMIT);
    const chr = charactersLength - (totalUnits || 0) * MSG_CHAR_LIMIT;

    if (charactersLength === 0 && attachments.length === 0) {
      setUnits(0);
    } else if (charactersLength > 0) {
      let val = totalUnits + 1 + attachments?.length * 3;
      setUnits(val);
    } else {
      let val = attachments?.length * 3;
      setUnits(val);
    }

    setCharacters(chr);
  }, [inputValue, attachments]);

  useEffect(() => {
    return () => {
      dispatch(clearConversationById());
    };
  }, []);

  const sendNewMessage = () => {
    if (!did || !conversationUser?.id) {
      notification.error({
        message: t("notification.sendMessageFail") + '. ' + t("notification.tryRefresh")
      });
      setLoading(false);
      return;
    }

    let data: any = {
      content: replaceContentVariables(inputValue, variableList),
      units: units,
      variableList: variableList,
    };

    if (attachments?.length > 0) {
      data["fileLocations"] = attachments;
    }

    try {
      dispatch(
        RequestAppAction.sendMessageRequest({
          didId: did,
          id: conversationUser.id,
          data: data,
          cbSuccess: (res: any) => {
            setInputValue("");
            setAttachments([]);

            if (res?.data) {
              setMessages((pre: IMessages) => ({
                ...pre,
                items: [res.data, ...(pre.items ?? [])],
              }));
            }

            setLoading(false);

            // dispatch(
            //   RequestAppAction.fetchConversations({
            //     id: did,
            //     meta: PAGINATION,
            //     cbFailure: (e: string) => {
            //       notification.error({ message: e });
            //     },
            //   })
            // );

            dispatch(
              RequestAppAction.fetchConversationsById({
                id: conversationUser.id,
                meta: PAGINATION,
                cbSuccess: (data: any) => {
                  if (data?.data) {
                    dispatch(setAllConversationsById(data));
                  }
                  setPagination((pre: any) => ({
                    ...pre,
                    page: PAGINATION.page + 1,
                  }));
                },
                cbFailure: (e: string) => {
                  notification.error({
                    message: e ?? t("notification.fetchConversationsByIdFail")
                  });
                },
              })
            );
          },
          cbFailure: (e: string) => {
            setAttachments([]);
            setLoading(false);
            notification.error({
              message: e ?? t("notification.sendMessageFail")
            });
          },
        })
      );
    } catch (errorInfo: any) {
      notification.error({
        message: errorInfo ?? t("notification.sendMessageFailUnexpected")
      });
    }
  };

  const items: MenuProps["items"] = templates?.items?.map(
    (template: { content: any; title: string }, index: any) => ({
      key: String(index),
      label: template.title,
      content: template?.content,
    })
  );

  const handleMenuItemClick = (item: any) => {
    if (item.key === "Add New Template") {
      add();
    } else {
      setInputValue(item.content);
    }
  };

  items?.push({ key: "Add New Template", label: "Add New Template" });

  const menuItems = items && items.map((menuItem: any) => ({
    key: menuItem.key,
    label: (
      <div onClick={() => handleMenuItemClick(menuItem)}>
        {menuItem.label}
      </div>
    ),
  }));

  const menu = () => (
    <Menu items={menuItems} />
  );

  const add = () => {
    form.resetFields();
    setTimeout(() => {
      templateModalRef.current?.openModal(t("modalHeading.createNewTemplate"));
    }, 100);
  };

  const addMessage = () => {
    form.resetFields();
    setTimeout(() => {
      if (conversationUser) {
        scheduleMessageModalRef.current?.openModal(
          `Create New Scheduled Message for ${
            conversationUser?.firstName || conversationUser?.phoneNumber
          }`
        );
      }
    }, 100);
  };

  tempDid = did;

  // const socketUpdateList = (res: any) => {
  //   if (res?.contactId === tempUser?.id || res?.senderDid === tempDid) {
  //     if (tempUser?.id) {
  //       dispatch(
  //         RequestAppAction.fetchConversationsById({
  //           id: tempUser.id,
  //           meta: PAGINATION,
  //           cbSuccess: () => {
  //             setPagination((pre: any) => ({
  //               ...pre,
  //               page: PAGINATION.page + 1,
  //             }));
  //           },
  //           cbFailure: (e: string) => {
  //             notification.error({ message: e });
  //           },
  //         })
  //       );
  //     }

  //     dispatch(
  //       RequestAppAction.fetchConversations({
  //         id: tempDid,
  //         meta: PAGINATION,
  //         cbSuccess: () => {
  //           setPagination((pre: any) => ({
  //             ...pre,
  //             page: PAGINATION.page + 1,
  //           }));
  //         },
  //         cbFailure: (e: string) => {
  //           notification.error({ message: e });
  //         },
  //       })
  //     );
  //   }
  // };

  const onFinishEditContact = (val: any) => {
    const param = {
      firstName: val?.firstName?.trim(),
      lastName: val?.lastName?.trim(),
      phoneNumber: val?.phoneNumber?.trim()?.replace(/[\s-]/g, ""),
      email: val?.email?.trim(),
      companyName: val?.companyName?.trim(),
    };

    dispatch(
      RequestAppAction.editUser({
        id: conversationUser?.id,
        data: param,
        cbSuccess: (res: any) => {
          dispatch(setConversationUser(res?.data));
          notification.success({ message: t("notification.success") });
          setTimeout(() => {
            editContactModalRef.current?.closeModal();
          }, 200);
          // dispatch(
          //   RequestAppAction.fetchConversations({
          //     id: did,
          //     meta: PAGINATION,

          //     cbFailure: (e: string) => {
          //       notification.error({
          //         message: e ?? t("notification.fetchConversationsFail")
          //       });
          //     },
          //   })
          // );
        },
        cbFailure: (e: string) => {
          notification.error({
            message: e ?? t("notification.editContactFail")
          });
        },
      })
    );
  };

  useEffect(() => {
    if (state) {
      const handler = setTimeout(() => {
        if (!PhoneNumberRegex.test(state)) {
          form.setFields([
            {
              name: "phoneNumber",
              errors: [t("notification.numberMustBeValid")],
            },
          ]);
        }
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [state]);

  const onSave = async (values: any, id: any) => {
    try {
      await form.validateFields();
      const data = {
        content: values.content,
        title: values.title,
      };
      dispatch(
        RequestAppAction.postTemplate({
          data: data,
          id: id,
          cbSuccess: () => {
            notification.success({
              message: t("notification.saveTemplateSuccess"),
            });
            dispatch(
              RequestAppAction.fetchTemplate({
                id: did,
                meta: PAGINATION,
                cbFailure: (e: string) => {
                  notification.error({
                    message: e ?? t("notification.getTemplateFail")
                  });
                },
              })
            );
            templateModalRef.current?.closeModal();
          },
          cbFailure: (e: string) => {
            notification.error({
              message: e ?? t("notification.saveTemplateFail")
            });
          },
        })
      );
    } catch (errorInfo: any) {
      notification.error({
        message: errorInfo ?? t("notification.saveTemplateFail")
      });
    }
  };

  const upload = (e: any) => {
    const formData = new FormData();
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxSize) {
        // Handle error: File size exceeds 5MB
        notification.error({
          message: t("common.fileSizeError", { name: file.name }),
        });

        return;
      }
    }

    for (const file of files) {
      formData.append("files", file);
    }

    dispatch(
      RequestAppAction.uploadFiles({
        data: formData,
        cbSuccess: (res: any) => {
          uploadFileRef.current.value = "";
          setAttachments((pre: any) => [...pre, ...res?.data]);
        },
        cbFailure: (e: string) => {
          uploadFileRef.current.value = "";
          notification.error({
            message: e ?? t("notification.uploadFileFail")
          });
        },
      })
    );
  };

  useEffect(() => {
    setAttachments([]);
  }, [conversationUser]);

  const onSendMessage = () => {
    if (!conversationUser) {
      return;
    }

    setLoading(true);
    if (
      inputValue?.trim().length > 0 ||
      attachments?.length > 0
    ) {
      sendNewMessage();
    }
  };

  const restrictedTooltip = (children: any) => {
    if (isPending || currentUserData?.isInboundOnly) {
      const toolTipTitle = isPending
        ? t("plan.notAvailableWhileActivationProcess")
        : t("plan.notAvailableWithThisPlan");

      return (
        <Tooltip title={toolTipTitle}>
          {children}
        </Tooltip>
      );
    }

    return children;
  };

  return (
    <Layout
      className="conversation-page"
      style={{ background: Colors.reciverBg }}
    >
      <Sider
        // breakpoint="md"
        // collapsedWidth="0"
        width={
          width ? (width > 992 ? 450 : width > 768 ? 350 : width > 576 ? 300 : 250) : 450
        }
        style={{ background: Colors.BgListing }}
      >
        <ListingSidebar
          templateModalRef={templateModalRef}
          openModalFav={isPending || currentUserData?.isInboundOnly ? () => {} : openModal}
          // socketUpdateList={socketUpdateList}
          getUserFun={(val) => {
            tempUser = val;
          }}
          // fetchNewConversationByIdData={fetchNewData}
        />
      </Sider>
      <Layout className="converstion-content">
        <Header className="bg-primary pl-5 pr-5">
          <Row>
            <Col
              span={18}
              className="d-flex align-items-center justify-content-start"
            >
              <div style={{ paddingRight: "2rem" }}>
                <Avatar
                  size={40}
                  icon={
                    conversationUser?.firstName ? (
                      conversationUser?.firstName?.charAt(0).toUpperCase()
                    ) : (
                      <UserOutlined />
                    )
                  }
                  style={{
                    background: Colors.IconBackground,
                    color: Colors.IconColor,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "3px",
                  justifyContent: "center",
                  marginTop: 0,
                }}
                className="subheader-user-info"
              >
                <Title
                  level={2}
                  style={{ margin: 0 }}
                  className="font-size-16 text-white text-start"
                >
                  <span>
                    {conversationUser?.firstName ? (
                      conversationUser?.firstName + " " + conversationUser.lastName
                    ) : (
                      conversationUser?.phoneNumber
                    )}
                  </span>

                  {conversationUser?.companyName && (
                    <span>
                      {" "}({conversationUser.companyName})
                    </span>
                  )}
                </Title>

                {conversationUser?.firstName && (
                  <Text className="font-size-10 font-weight-300 text-white text-start">
                    {conversationUser?.phoneNumber}
                  </Text>
                )}
              </div>
            </Col>
            <Col
              span={6}
              className="conversation-btns text-right align-items-center d-flex"
            >
              <Btn
                onClick={() => {
                  setIsRefetching(true);
                  fetchNewData();
                }}
                icon={<ReloadOutlined />}
                style={{ background: Colors.white }}
                disabled={isRefetching || !conversationUser?.id}
              >
                Refresh
              </Btn>

              <Btn
                onClick={() => {
                  if (conversationUser) {
                    form.setFieldsValue({
                      firstName: conversationUser?.firstName,
                      lastName: conversationUser?.lastName,
                      phoneNumber: conversationUser?.phoneNumber,
                      email: conversationUser?.email,
                      companyName: conversationUser?.companyName,
                    });
                    editContactModalRef.current?.openModal(
                      t("heading.editContact")
                    );
                  }
                }}
                icon={<EditOutlined />}
                shape="circle"
                style={{ background: Colors.white }}
              />

              <Btn
                onClick={() => {
                  if (conversationUser?.id) {
                    dispatch(
                      RequestAppAction.downloadConversation({
                        id: conversationUser?.id,
                        cbSuccess: (blob: any) => {
                          const url = window.URL.createObjectURL(
                            new Blob([blob.data])
                          );
                          const link = document.createElement("a");
                          link.href = url;
                          const firstName =
                            conversationUser?.firstName || conversationUser?.phoneNumber;
                          link.setAttribute(
                            "download",
                            `Export_${
                              firstName + (conversationUser?.lastName ? conversationUser?.lastName : "")
                            }_${new Date().getTime()}.csv`
                          );

                          document.body.appendChild(link);

                          link.click();
                          if (link.parentNode) {
                            link.parentNode.removeChild(link);
                          }
                        },
                        cbFailure: (e: string) => {
                          notification.error({
                            message: e ?? t("notification.downloadConversationFail")
                          });
                        },
                      })
                    );
                  }
                }}
                icon={<SaveOutlined />}
                shape="circle"
              />
            </Col>
          </Row>
        </Header>

        <input
          ref={uploadFileRef}
          onChange={(e) => {
            const value = e.target.value.toLowerCase();

            if (isValidFile(value)) {
              upload(e);
            } else {
              notification.error({
                message: t("common.fileNotSupported")
              });
            }
          }}
          style={{ display: "none" }}
          type="file"
          multiple
        />

        <ConversationMessages
          conversationUser={conversationUser}
          messages={messages}
          scrollRef={scrollRef}
          isLoading={
            isUploading ||
            (sendingMessage && attachments?.length > 0) ||
            isRefetching ||
            scrollFetch ||
            isSelectingContactUser
          }
        />

        <Row className="message-footer">
          <Col span={24}>
            <Col
              style={{
                display: "flex",
                background: "transparent",
                overflowX: "auto",
              }}
              span={24}
            >
              {attachments.map((i: string, ind: number) => (
                <span
                  key={`message-attachment-${ind}`}
                  style={{
                    position: "relative",
                    marginRight: "0.5rem",
                    marginTop: "0.5rem",
                    zIndex: 1,
                  }}
                >
                  <Tag
                    onClick={() => {
                      window.open(i, "_blank");
                    }}
                    style={{
                      background: Colors.white,
                      borderRadius: 4,
                    }}
                  >
                    <Text
                      key={ind}
                      className="font-size-14"
                      style={{
                        display: "flex",
                        cursor: "pointer",
                      }}
                    >
                      {returnElipsesText(i)}
                    </Text>
                  </Tag>
                  <div
                    style={{
                      position: "absolute",
                      top: -8,
                      right: 12,
                    }}
                  >
                    <CloseCircleFilled
                      onClick={() => {
                        const arr = attachments;
                        setAttachments(
                          arr.filter((item: any) => item !== i)
                        );
                      }}
                      style={{
                        color: Colors.red,
                        position: "absolute",
                      }}
                    />
                  </div>
                </span>
              ))}
            </Col>
          </Col>
          <Col span={17} className="d-flex-col align-items-center">
            <Row
              style={{ width: "100%" }}
              className={`counter-text ${width > 310 ? "d-flex" : "d-flex-column align-items-start"} font-size-14`}
            >
              <div>
                {t("sideBar.chars")} {characters}/{MSG_CHAR_LIMIT}
              </div>
              <div className="pl-3">
                {t("sideBar.msgUnits")} {units}/{MSG_UNIT_LIMIT}
              </div>
            </Row>
            <Row className="d-flex align-items-center">
              {restrictedTooltip(
                <TextArea
                  disabled={isPending || currentUserData?.isInboundOnly}
                  onKeyDown={(e) => {
                    if (!conversationUser) return;
                    if (units > MSG_UNIT_LIMIT) return;
                    if (e.key === "Enter" && !e.shiftKey) e.preventDefault();
                    if (
                      inputValue.trim().length === 0 &&
                      attachments.length === 0 &&
                      !isLoading
                    )
                      return;

                    if (!isLoading && e.key === "Enter" && !e.shiftKey) {
                      sendNewMessage();
                    }
                  }}
                  style={{
                    resize: "none",
                    background: "white",
                    paddingRight: "3.4rem",
                  }}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder={t("placeholder.enterToSendMessage")}
                />
              )}
              <SendButton
                btnHtmlType="button"
                isDisabled={
                  isPending ||
                  currentUserData?.isInboundOnly ||
                  units > 10 ||
                  (inputValue?.trim().length < 1 && attachments?.length < 1) ||
                  isLoading ||
                  loading ||
                  isRefetching
                }
                isLoading={units > 10 ? false : isLoading}
                btnIconStyle={units > 10 ? {
                  color: Colors.TitleGray,
                  fontSize: "1.6rem",
                } : {
                  color:
                    inputValue.trim().length > 0 ||
                    attachments.length > 0
                      ? Colors.DarkCharcoal
                      : Colors.TitleGray,
                  fontSize: "1.6rem",
                }}
                btnStyle={{
                  marginLeft: "-3.2rem",
                  border: "none",
                  background: "none",
                  boxShadow: "none",
                }}
                onBtnClick={onSendMessage}
                tooltipTitle={
                  isPending
                    ? t("plan.notAvailableWhileActivationProcess")
                    : currentUserData?.isInboundOnly
                    ? t("plan.notAvailableWithThisPlan")
                    : units > 10
                    ? t("message.unitError")
                    : ""
                }
              />
            </Row>
          </Col>
          <Col
            span={6}
            className="send-msg-btns d-flex align-items-center justify-content-evenly"
          >
            {restrictedTooltip(
              <Btn
                disabled={isPending || currentUserData?.isInboundOnly}
                onClick={isPending || currentUserData?.isInboundOnly ? () => {} : addMessage}
                style={{ background: Colors.white }}
                className={`${isPending || currentUserData?.isInboundOnly ? "" : "btn-hover"} send-msg-btn`}
                icon={
                  <Tooltip title={t("tootlTip.ScheduleMsg")}>
                    <ClockCircleOutlined />
                  </Tooltip>
                }
              />
            )}
            {restrictedTooltip(
              <Btn
                disabled={isPending || currentUserData?.isInboundOnly}
                style={{ background: Colors.white }}
                onClick={
                  isPending || currentUserData?.isInboundOnly
                    ? () => {}
                    : () => uploadFileRef.current.click()
                }
                className="send-msg-btn"
                icon={
                  <Tooltip title={t("tootlTip.attachFile")}>
                    <PaperClipOutlined />
                  </Tooltip>
                }
              />
            )}
            {isPending || currentUserData?.isInboundOnly ? (
              restrictedTooltip(
                <Btn
                  disabled={isPending || currentUserData?.isInboundOnly}
                  style={{ background: Colors.white }}
                  className="send-msg-btn"
                  icon={<ProfileOutlined />}
                />
              )
            ) : (
              <Dropdown dropdownRender={menu} placement="topRight" arrow>
                <Btn
                  disabled={isPending || currentUserData?.isInboundOnly}
                  style={{ background: Colors.white }}
                  className="send-msg-btn"
                  icon={<ProfileOutlined />}
                />
              </Dropdown>
            )}
          </Col>
        </Row>
      </Layout>

      <TextifyModal ref={editContactModalRef}>
        <div style={{ paddingTop: 25, paddingBottom: 15 }}>
          <Form onFinish={onFinishEditContact} name="userForm" form={form}>
            <Form.Item
              rules={[{ required: true, message: t("fields.nameError") }]}
              name={"firstName"}
              initialValue={conversationUser?.firstName}
            >
              <Input
                placeholder="Enter Name"
                prefix={<UserOutlined className="site-form-item-icon" />}
                suffix={
                  <CloseCircleFilled
                    onClick={() => {
                      form.setFieldsValue({ firstName: "" });
                    }}
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                }
              />
            </Form.Item>
            <Form.Item name={"lastName"} initialValue={conversationUser?.lastName}>
              <Input
                placeholder="Enter Last Name"
                prefix={<UserOutlined className="site-form-item-icon" />}
                suffix={
                  <CloseCircleFilled
                    onClick={() => {
                      form.setFieldsValue({ lastName: "" });
                    }}
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                }
              />
            </Form.Item>
            <Form.Item
              name={"phoneNumber"}
              rules={[{ required: true, message: t("fields.numberError") }]}
              initialValue={conversationUser?.phoneNumber}
            >
              <Input
                onChange={(e) => {
                  setState(e.target.value);
                }}
                onKeyDown={(e) => {
                  const key = e.key;
                  if (
                    !/[0-9]/.test(key) &&
                    key !== "ArrowLeft" &&
                    key !== "ArrowRight" &&
                    key !== "+" &&
                    key !== "Backspace"
                  ) {
                    e.preventDefault();
                  }
                }}
                placeholder="Phone Number"
                prefix={<PhoneOutlined className="site-form-item-icon" />}
                suffix={
                  <CloseCircleFilled
                    onClick={() => {
                      form.setFieldsValue({ phoneNumber: "" });
                    }}
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                }
              />
            </Form.Item>
            <Form.Item name={"email"} initialValue={conversationUser?.email}>
              <Input
                placeholder="Email"
                prefix={<MailOutlined className="site-form-item-icon" />}
                suffix={
                  <CloseCircleFilled
                    onClick={() => {
                      form.setFieldsValue({ email: "" });
                    }}
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                }
              />
            </Form.Item>
            <Form.Item name={"companyName"} initialValue={conversationUser?.companyName}>
              <Input
                placeholder="Company Name"
                suffix={
                  <CloseCircleFilled
                    onClick={() => {
                      form.setFieldsValue({ companyName: "" });
                    }}
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                }
              />
            </Form.Item>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button buttonType="submit" title={t("button.save")} />
              <div style={{ marginRight: "1rem" }}>
                <Button
                  btnClass="outlined_btn"
                  onBtnClick={() => {
                    editContactModalRef.current?.closeModal();
                  }}
                  title={t("button.cancel")}
                />
              </div>
            </div>
          </Form>
        </div>
      </TextifyModal>
      <AddTemplateModal
        isEdit={false}
        ref={templateModalRef}
        dId={did}
        form={form}
        onSave={onSave}
        onUpdate={() => {}}
      />
      <AddScheduleMessageModal
        isEdit={false}
        modalRef={scheduleMessageModalRef}
        contactId={conversationUser?.id}
        form={form}
      />
      <BulkMessageModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        loading={loading}
        setLoading={setLoading}
      />
    </Layout>
  );
};

export default Converstion;
